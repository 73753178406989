import React from 'react';
import { graphql} from 'gatsby';
import Team from '../../templates/multilanguage/Team';

export default ({ data }) => {
  return <Team data={data} />
};

export const query = graphql`
 query TeamRo {
  mdx(frontmatter: {type: {eq: "team"} language: {eq: "fr"}}) {
      id
      fields {
        slug
        canonical
        lang
        relatedPages {
          en {
            url
            country
          }
          it {
            url
            country
          }
          ro {
            url
            country
          }
          fr {
            url
            country
          }
          es {
            url
            country
          }
          de {
            url
            country
          }
          pl {
            url
            country
          }
          cz {
            url
            country
          }
        }
      }
      exports {
        team {
          name
          title
          image {
           childImageSharp {
            gatsbyImageData(
              quality: 100
              tracedSVGOptions: {color: "#00ab6b"}
              transformOptions: {cropFocus: ENTROPY}
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
          }
          alt
          description
        }
      }
      frontmatter {
        type
        title
        metaTitle
        metaDescription
        subtitle
        descriptionTexts
      }
  }
}
`
